
// html{
//     body{
//         height: 94%;
//     }
// }

.page-heading{
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 10px;
}

.media-body{
  width: 1000px;
  display: table-cell;
}

// .top-navigation .nav > li.active {
//   background: none;
// }

.bg-info-light{
  background: #d9edf7;
}

.bg-danger-light{
  background: #f2dede;
}

.bg-success-light{
  background: #dff0d8;
}

.bg-warning-light{
  background: #ffffbb;
}

/* iCheck plugin Square skin, green
----------------------------------- */
.icheckbox_square-green,
.iradio_square-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    background: url(./green.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_square-green {
    background-position: 0 0;
}
.icheckbox_square-green.hover {
    background-position: -24px 0;
}
.icheckbox_square-green.checked {
    background-position: -48px 0;
}
.icheckbox_square-green.disabled {
    background-position: -72px 0;
    cursor: default;
}
.icheckbox_square-green.checked.disabled {
    background-position: -96px 0;
}

.iradio_square-green {
    background-position: -120px 0;
}
.iradio_square-green.hover {
    background-position: -144px 0;
}
.iradio_square-green.checked {
    background-position: -168px 0;
}
.iradio_square-green.disabled {
    background-position: -192px 0;
    cursor: default;
}
.iradio_square-green.checked.disabled {
    background-position: -216px 0;
}

.wrapper{
    padding: 0;
}

//Fix an issue with bootstrap drop down menu on the right edge of the .page-heading
.pull-right .dropdown-menu:after {
    left: auto;
    right: 13px;
}
.pull-right .dropdown-menu {
    left: auto;
    right: 0;
}

.popover{
    z-index: 9999;
}

.engagement-view{
    a.body-link{
        color: white;
        &:hover{
            .widget{
                h1{
                    color: black !important;
                }
                p{
                    color: black !important;
                }
            }
        }
    }
}

.engagement-selector{
    .engagement-tile{
        .widget{
            .content-row{
                min-height: 215px;
            }

            div.watermark{
                font-size: 72px;
                position: absolute;
                top: 0;
                right: 20px;
                opacity: 0.3;
            }

            div.engagement-tile-name{
                h2{
                    font-size : 1em;
                    font-weight: 600;
                    // height: 1.5em;
                    overflow: hidden;

                    &:after {
                        content: "";
                        text-align: right;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 30%;
                        height: 1.2em;
                        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
                        }
                }
                // max-height: 78px;
                // height: 78px;
                // overflow: auto;
            }

            a.body-link{
                color: #676a6c;
                h2{
                    color: black;
                }

                &:hover{
                    color: black;
                }
            }

            .btn-link{
                color: #676a6c;

                &:hover{
                    background-color: #f3f3f4; //white;
                    color: black;
                }
            }
        }
    }
}


@media (min-width: 768px) {
  #page-wrapper {
    min-height: 400px;
  }
}
@media (max-width: 768px) {
  #page-wrapper {
    min-height: 400px;
  }
}