.report-body-style{
  background: #fff;
}

#reporting-page{
  margin : 20px 30px 10px 30px;
  
  .report-header{
      border-bottom: 1px solid #333;
      padding-bottom: 10px;

      h1{
          font-size: 26px;
          font-weight: 100;
      }        
  }

  .report-row-darker{
      background: #999;
      color: #000;

      .custom-checkbox{
          margin-top: 2.5px;
          margin-bottom: 0;
          font-weight: 500;

          div.icheckbox_square-green{
              background-color: white;
              margin-right: 5px;
          }
      }
  }
  .report-row-dark{
      background: #ccc;
      color: #333;
  }
  .report-row-semidark{
      background: #eee;
      color: #333;
  }

  .checklist-part{
      padding-bottom: 10px;
      border-bottom: 1px solid #333;
  }

  .report-header{
      .report-header-row{
          margin-top: 10px;        
      }
      .report-header-label{
          font-size: 14px;
          font-weight: 300;
      }
      .report-header-value{
          font-size: 16px;
          font-weight: 500;
      }
      h3{
          margin-top: 10px;
      }
  }

  .checklist-part{
      .section-row{
          font-size: 18px;
          font-weight: 600;
      }

      .answer-col{
          font-weight: 600; 
          vertical-align: top; 
      }
      .by-col, .date-col{
          vertical-align: top;
          
      }
      .question-col{
          p{
              margin-top: 5px;
              margin-left: 10px;
              font-weight: 600;
          }
          .assertion-text{
              font-weight: 500;
          }
          
      }
      div.input-item{
          margin-top: 5px;
          margin-left: 10px;
          font-weight: 600;
          padding-right: 5px;
          
          .input-row{
              border-top: 0.5px dashed lightgray;
              
              .input-caption, .input-username, .input-date{
                  font-weight: 400;
              }
              .comment-answer{
                  font-weight: 400;
                  font-style: italic;
              }
              .input-spacer{
                  height: 13.6px;
              }            
          }
      }
      .id-header{
          width: 65px;
      }
      .by-header{
          min-width: 110px;            
      }
      // .question-header{
      //     width   : 80%;
      // }
      .row-header{
          padding-bottom: 10px;
          // border-bottom: 1px solid #333;
      }
  }

  .report-page{
    width   : 100%;

      font-size: 10px !important;
      h1, h2, h3{
          font-size: 10px !important;
      }
      h2{
          margin-top: 10px;
      }
      .report-header-label, .report-header-value, .section-row{
          font-size: 10px;
      }

      .loading-indicator{
          font-size: 14px !important;     //don't want the loading text too small
      }

      .print-button{
          margin-top: -5px;
      }
      .label-col{
          padding-left: 10px;
          padding-right: 10px;
      }
      .checklist-header{
          padding-bottom: 0;
      }

      .watermark{
          position: absolute;
          opacity: 0.25;
          font-size: 5em;
          width: 100%;
          text-align: center;
          z-index: -1;
          transform:rotate(-45deg);  
          margin-top: 10%;
          font-weight: bold;
      }
  }

  .reviewset-footer{
      .status-row{
          .status-notes-col{
              padding-top: 2.5px !important;
          }
      }
  }
}

.doc-data-table{
    td.data-key{
        width       : 8.33333%;
        text-align  : right;
        background  : palegoldenrod;

        width       : 15%;
        // border-top  : none;
    }
    td.doc-props-label-col{
        width: 15%;
    }
    
    margin: 5px 10px 10px 10px;
    width: calc(100% - 20px);
}

@media print {
    td.doc-props-label-col{
        width: 175px;
    }
}