//For Debugging - the compiled version will load bootstrap, even though it's only used in reporting.
// so for debugging purposes, include it here as well until we can fully remove it from reporting.
// @import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css');

html{
    font-size   : 16px;
}
#root{
    height  : 100%;
}
body {
    margin: 0;
    background-color: rgb(250, 250, 250);
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a {
    text-decoration: none;
    color: #337ab7;

    &:hover:not(.MuiButtonBase-root), &:focus:not(.MuiButtonBase-root) {
        color: #23527c;
        text-decoration: none;
    }
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 3px;
}

.btn-link {
    &.active {
        color: #1ab394;
    }
}

.notification-view{
    .notification-list{
        li{
            margin-bottom: 5px;
            font-size: 1.2em;
        }
    }
}

.loading-indicator{
    margin-top: 20px;
    .sk-spinner{
        display: inline-block;
        margin-right: 10px;
    }

}

.sub-header{
    margin-top: 50px;
    .reviewset-header-col{
        height: 65px;
        white-space: nowrap;
    }
}

.page-heading{
    h2{
        margin-top: 10px;
    }
    .breadcrumbs{
        margin-bottom: 0;
        li{
            display: inline-block;
            margin-left: 3px
        }  
        li:first-child{
            margin-left: 0;
        }  
    }
}

.page-header{
    text-align: center;
    color: #666;

    h2{
        font-size: 36px;
        font-weight: 300;
    }
    p{
        font-size: 16px;
        margin: 0 0 20px 0;
    }
}

.page-subheader{
    text-align: center;
    color: #666;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;

    h2{
        font-size: 32px;
        font-weight: 300;
        margin-top: 10px;
    }
    p{
        font-size: 16px;
        margin: 0 0 20px 0;
    }
}

// .home-page{
    .home-links-row{
        margin-top: 40px;

        .home-nav-link{
            text-align: center;
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 10px;
            // width: 75%;
            // margin: 0 auto;
            width: 150px;
            height: 100px;
            display: inline-block;
            margin: 0 10px;

            p{
                margin-top: 10px;
                font-size: 24px;
                font-weight: 300;
            }
            span.glyphicon{
                display: block;
                font-size: 36px;
            }
            i.fa{
                display: block;
                font-size: 36px;
            }
            a:hover{
                text-decoration: none !important;
            }
        }
        .home-nav-link:hover{
            background: #eee;
            border-color: #999;
        }
    }
// }

.new-doc-div{
    border: 1px solid darkgray;
    background: #eee;
    padding: 10px; 
    min-height: 155px;   
}

// .doc-view-header{
    // border-bottom: 1px solid darkgray;
    // margin-bottom: 20px;
// }

.checklist-container{
    margin-bottom: 10px;
    
    .panel-heading{
        h3{
            display: inline-block;
        }
        .checklist-progress{
            padding-top: 10px;
            margin-right: 10px;
        }
    }

    .media{
        padding: 10px;
        border-radius: 3px;
        margin-top: 5px;
    }

    .checklist-button-container{
        min-width: 150px;
        
        .approval{
            display: inline-block;
        }

        .checklist-button{
            min-width: 35px;
            margin-right: 10px;
        }
        .checklist-link-button{
            margin-left: 10px;
            padding: 5px;
        }
    }

}

.reviewsets-page{
    padding: 0 10px;
    height: 100%;

    .reviewsets-page-menu{
        padding: 10px;

        .btn-primary{
            width: 100%;
            margin-bottom: 20px;
        }
        .reviews-menu-list, .reviews-my-list {
            li{
                margin-bottom: 10px;
                button, a{
                    font-size: 16px;
                    font-weight: 300;
                    padding: 5px;
                    border-radius: 2px;
                }
            }
            li.active{
                background: #ccc;
                button{
                    color: #333;
                }
            }
        }

        .reviews-my-list{
            margin-top: 20px;
            padding-top: 10px;
            border-top: 1px solid #eee;
        }
    }

    .reviewsets-page-content{
        padding: 10px;
    }

    .set-list{
        border: 1px solid #e7eaec;
        border-radius: 0;

        .modified-by{
            font-size: 12px;
            font-weight: 300;
        }
        .modified-on{
            font-size: 8px;
            font-weight: 300;
        }
    }
}

.reviewset-page{

    .reviewset-edit{
        .item-title-row{
            margin-bottom: 5px;
            padding-top: 20px;

            input, textarea{
                margin-bottom: 10px;
            }
            button, .btn{
                margin-left: 10px;
            }
            input.name-input{
                font-size: 22px;
            }
        }
        .item-attrs{
            margin-bottom: 20px;
            input{
                margin-bottom: 10px;
            }
            .label-col{
                margin-top: 7px;
            }
            .value-col-fixed{
                margin-top: 7px;
                margin-bottom: 10px;
            }
            .progress{
                height: 34px;
            }
        }
    }
}

.doc-view{
    .doc-tabs{
        padding-bottom: 20px;

        li.active {
            background: none;
        }
    }
}

.checklist{
    border-left: 1px solid rgb(231, 234, 236);
    border-right: 1px solid rgb(231, 234, 236);
    border-bottom: 1px solid rgb(231, 234, 236);
    &.checklist-editor{
        border-top: 1px solid rgb(231,234,236);
    }
    .checklist-description{
        padding-top: 15px;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 300;
    }
    .checklist-section{
        padding: 10px;
        // padding-top: 20px;

        .checkitem-section-key{
            font-size: 20px;
            font-weight: 400;
        }
        .checkitem-section-name{
            font-size: 20px;
            font-weight: 400;
            padding-bottom: 10px;
            border-bottom: 1px solid #999;
        }
    }
    .checkitem-assertion{
        &:hover, &:focus{
            background-color: #fcf8e3;
        }
    }
    .checkitem-section{
        background-color: #d9edf7;
        color: #31708f;
    }
    .checkitem{
        border-bottom: 1px dotted #999;
        padding: 10px 0;
        margin-top: 5px;

        .checkitem-key{
            font-size: 16px;
            font-weight: 400;
        }
        .checkitem-body h4{
            font-size: 18px;
            font-weight: 300;
        }
        .checkitem-button-container{
            min-width: 150px;

            &.with-na{
                min-width: 150px;
            }

            .checkitem-button{
                margin-right: 5px;
            }
            .checkitem-undo-button{
                margin-right: 10px;
                float: right;
            }
        }

        label.custom-checkbox{
            font-weight: 400;
        }
    }
    .checkitem:last-child{
        border-bottom: none;
    }
    .checklist-footer{
        padding: 10px;
        border-top: 1px solid rgb(231, 234, 236);
    }
}

.checklist-viewer{
    border: none;
}

.bg-danger{
    .btn-default{
        color: #333;
    }
}

.checklist-editor{
    input.list-name{
        font-size: 20px;
    }

    table.checklist-item-editor{
        .checklist{
            border-width: 0;
        }

        tr.checkitem-editor{
            &:focus{
                background: lightblue;
            }        
            // &.active{
            //     td.checklist-section{
            //         background: green;
            //     }                
            // }

            div.assertion-col{
                padding-right: 25px;
            }
            
            div.input-editor-row{
                div.input-editor{
                    border-left: 2px solid #aaa;
                    margin-bottom: 1px;

                    label{
                        margin-left: 5px;
                        margin-top: 5px;
                        font-weight: 400;
                    }

                    h5{
                        border-left: 1px solid #aaa;
                        margin-left: 0;
                        margin-top: -2px;
                    }
                }

            }
        }

        tr.checkitem{
            border-bottom: 1px dotted #999; 
            .checklist-section{
                padding-top: 10px;

                .checkitem-section-name{
                    border-bottom: none;
                }
            }

            td.checkitem-actions{
                width: 110px;
            }
            td.checkitem-key{
                width: 35px;
            }
            td.checkitem-body{
                h4{
                    margin-top: 2.5px;
                }
                .checkitem-text-container{
                    textarea:disabled{
                        background-color: #f5f5f5;
                    }
                }
            }
            .spacer-1{
                margin-left: 10px;
            }
            .spacer-2{
                margin-left: 20px;
            }
            .section-content-col{
                width: 100% !important;
            }
            .section-actions-col{
                width: 75px !important;
                float: right;
            }

            .checkitem-spacer-1{
                width: 10px !important;
            }
            .checkitem-spacer-2{
                width: 20px !important;
            }
            .checklist-footer{
                padding: 10px;
                border-top: 1px solid rgb(231, 234, 236);                      
            }
        }
    }
}

.sign-in-view{
    margin-top: 50px;
}

.bg-danger-light{
    background: #f2dede;

}
.bg-warning-light{
    background: #fcf8e3;
}
.bg-info-light{
    background: #d9edf7;
}
.bg-success-light{
    background: #dff0d8;
}
.bg-primary-light{
    background: #337ab7;
}
.text-center{
    text-align: center;
}
.p-10{
    padding: 10px;
}

.p-lr-0{
    padding-left: 0;
    padding-right: 0;
}

.p-r-0{
    padding-right: 0;
}

.m-lr-2{
    margin-left: 2px;
    margin-right: 2px;
}

.m-r-10{
    margin-right: 10px;
}
.m-l-10{
    margin-left: 10px;
}
.m-l-neg10{
    margin-left: -10px;
}
.m-t-0{
    margin-top: 0;
}
.m-t-20{
    margin-top: 20px;
}
.m-t-neg10{
    margin-top: -10px;
}
.m-b-0{
    margin-bottom: 0;
}
.m-b-5{
    margin-bottom: 5px;
}
.p-t-10{
    padding-top: 10px;
}
.p-t-20{
    padding-top: 20px;
}

.p-t-xs{
    padding-top: 2.5px;
}
.p-t-sm{
    padding-top: 5px;
}
.p-t-md{
    padding-top: 15px;
}
.p-t-lg{
    padding-top: 25px;
}

.p-b-md{
    padding-bottom: 15px;
}

.border-bottom-thick{
    border-bottom: 1.5px solid #999;
}
.border-color-medium{
    border-color: #ccc;
}

.fs-14{
    font-size: 14px;
}

.fs-sm{
    font-size: 10px;
}
.fs-md{
    font-size: 14px;
}
.fs-lg{
    font-size: 20px;
}
.fs-xlg{
    font-size: 24px;
}

.btn-w-xs{
    min-width: 50px;
}
.btn-w-sm {
    min-width: 70px;
}
.btn-w-md{
    min-width: 100px;
}
.btn-w-lg{
    min-width: 125px;
}

button.drop-down-button{
    margin: 0;
    padding: 7px 20px;
    border-radius: 3px;
    line-height: 25px;
    font-weight: normal;
    text-align: left;
    font-size: 12px;
}

.search-div{
    .btn-default{
        padding-bottom: 7px;
    }
    input{
        height: 34.5px;
    }
}

.item-title-row{
    margin-bottom: 15px;
    .btn{
        margin-left: 10px;
    }
} 

.item-attrs {
    margin-bottom: 20px;

    .item-attrs-row {
        margin-bottom: 8px;

        .item-label {
            font-size: 12px;
            color: #999;
            font-weight: 400;
            text-align: right;
        }
        .item-value {
            font-size: 16px;
            color: #333;
            font-weight: 300;
        }
        .label-col {
            text-align: right;
            padding: 5px;
        }
        .value-col-fixed {
        }
        .progress{
            margin-bottom: 0;
        }
    }
}

.page-menu{
    padding: 10px;

    .btn-grp{
        // margin-bottom: 20px;
        position: relative;
    }
    .btn-primary{
        width: 100%;
        margin-bottom: 20px;
    }
    .menu-list {
        li{
            margin-bottom: 10px;
            button, a{
                font-size: 16px;
                font-weight: 300;
                padding: 5px;
                border-radius: 2px;
            }
        }
        li.active{
            background: #ccc;
            button{
                color: #333;
            }
        }
    }

    .menu-list-secondary{
        margin-top: 20px;
        padding-top: 10px;
        border-top: 1px solid #eee;
    }
}

.grid-header{
    margin-bottom: 10px;
    h4{
        font-size: 24px;
        font-weight: 300;
        color: #404040;
    }
    // .nav-div{
    //     margin-left: 20px;
    // }
    .search-div{
        width: 60%;
    }
    .actions-div{
        button, a{
            min-width: 70px;
        }
    }
}

@media (min-width: 600px) {
    .grid-header {
        input.search-input {
            margin-top: 4px;
            height: 33.8px;
        }
    }
}

.new-item-div{
    border: 1px solid darkgray;
    background: #eee;
    padding: 10px; 
    min-height: 155px; 

    .header-row{
        border-bottom: 1px solid darkgray;
        background: #fff;
        margin: -10px -10px 10px -10px;
        padding-top: 5px; 
    }  

    button{
        min-width: 75px;
        margin-left: 10px;
    }
}

span.status{
    padding:  2px 4px;
    border-radius: 5px;

    &.status-pending{
        border: 1px solid #faebcc;
        background: #fcf8e3;
        color: #8a6d3b;
    }

    &.status-approved{
        border: 1px solid #bce8f1;
        background: #d9edf7;
        color: #31708f;
    }
}

.help-text{
    font-size: 10px;
    font-style: italic;
}

.bg-my-info{
    background: #d9edf7;
    color: #31708f;
}

.engagement-selector-table{
    td{
        padding-top: 15px;
    }
}

.report-body-style{
    background: #fff;
}

#doctypes-copy{
    .panel{
        height: 200px;

        .panel-heading{
            button{
                margin-top: -5px;
            }
        }
    }
}

.admin-view{
    .add-btn-div{
        margin-right: 15px;
    }
    
    .btn-add, .btn-edit{
        min-width: 75px;
        margin-left: 10px;
    }

    .panel-heading{
        .loading-indicator{
            margin-top: 10px;
            float: right;
        }
    }

    ul.sort-dropdown-menu{
        width: 200px;
    }
}

.tenant-metrics-view{
    .tenant-metrics-row{
        min-height: 405px;
    }    
    
    .loading-indicator{
        margin-top: 0;
    }
}
.metrics-view{
    .clt-widget{
        overflow-y: hidden;
    }
    .clt-widget-lg{
        height: 330px;
    }
    .clt-widget-activity{
        height: 340px;  //to match the two-row height
        overflow-y: scroll;
    }

    .clt-widget-md{
        height: 240px;
    }
    .clt-widget-sm{
        height: 165px;
    }

    .stat{
        i, span.fa{
            font-size: 4em;
        }
    }

    .stat-sm{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .recent-activity{
        .recent-activity-row{
            border-bottom: 1px dotted #aaa ;
            padding-bottom: 2.5px;
            margin-bottom: 10px;

            i, span.fa{
                font-size: 1.5em;
            }
        }
    }
    // .user-activity-content{
    //     height: 390px;
    //     overflow-y: scroll;
    // }

    // .stat-xs{
    //     padding-top: 10px;
    //     padding-bottom: 5px;
    // }    
    
}

.engagement-view{
    .widget{
        margin-top: 20px;
        
        .glyphicon{
            font-size: 32px;
        }
        h2{
            margin-top: 2.5px !important;
        }        
    }
}

.alert{
    .custom-checkbox{
        .icheckbox_square-green{
            &:not(.checked){
                border: 0.5px darkgray solid;
                background: white;
            }
        } 
    }
}

table.scroll-table{
    // height:250px;              // <-- Select the height of the table
    display: -moz-groupbox;    // Firefox Bad Effect
    
    tr {
        width: 100%;
        display: inline-table;
        table-layout: fixed;
    }

    tbody{
        overflow-y: scroll;      
        // height: 200px;            //  <-- Select the height of the body
        width: 95%;
        position: absolute;
    }

}

$toastr-width: 500px !default;
$successBgColor: #60bb71 !default;
$infoBgColor: #58abc3 !default;
$warningBgColor: #f7a336 !default;
$errorBgColor: #db6a64 !default;
$defaultSpace: 10px;

.redux-toastr {
  *, *:before, *:after {
    box-sizing: border-box;
  }

  .top-left, .top-right, .top-center, .bottom-left, .bottom-right, .bottom-center {
    max-width: $toastr-width;
    position: fixed;
    z-index: 99999999;
    padding: 0 $defaultSpace;
  }

  .top-left, .top-right, .top-center {
    top: 0;
  }

  .top-right, .bottom-right {
    right: 0;
  }

  .bottom-left, .bottom-right, .bottom-center {
    bottom: 0;
  }

  .top-left, .bottom-left {
    left: 0;
  }

  .top-center, .bottom-center {
    left: 50%;
    // margin-left: -($toastr-width / 2);
    margin-left: calc(-1 * ($toastr-width / 2));
  }

  @media (max-width: 320px) {
    .top-left, .top-right, .top-center, .bottom-left, .bottom-right, .bottom-center {
      width: $toastr-width - 30;
    }

    .top-center, .bottom-center {
      $width: $toastr-width - 30;
    //   margin-left: -($width / 2);
      margin-left: calc(-1 * ($width / 2));
    }
  }

  .toastr {
    background-color: #fcfcfc;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    margin: $defaultSpace 0;
    border-radius: 4px;
    position: relative;
    z-index: 2;
    color: #333;
    opacity: .94;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    
    &:hover:not(.message) {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
      opacity: 1;
    }

    .toastr-status {
      width: 100%;
      height: 5px;

      &.success {
        background-color: $successBgColor;
      }

      &.warning {
        background-color: $warningBgColor;
      }

      &.info {
        background-color: $infoBgColor;
      }

      &.error {
        background-color: $errorBgColor;
      }
    }

    .toastr-left-container, .toastr-right-container {
      float: left;
      text-align: center;
      overflow: hidden;
    }

    .toastr-left-container {
      width: 80px;
      top: 0;
      left: 0;
      position: absolute;
      bottom: 0;

      .holder {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 50%;
        margin-top: -35px;
        left: 5px;
        line-height: 60px;
      }

      .toastr-icon {
        fill: white;
        vertical-align: middle;
        margin-top: 5px;
      }
    }

    .toastr-middle-container {
    //   width: 65%;
    //   margin-left: 80px; 
      position: relative;
      float: left;
      font-family: Arial, Helvetica, sans-serif, sans-serif;
      font-size: 1em;
      text-align: left;
      padding: 10px 5px;
      
    //   .title {
    //     font-size: 1.1em;
    //     font-weight: bold;
    //     margin-bottom: 5px;
    //   }

      .title {
        width: 90%;
        // height: 50px;
        text-align: left;
        overflow: hidden;
        font-size: 1.2em;
        text-overflow: ellipsis;
        // white-space: nowrap;
        // line-height: 50px;
        padding: 0 20px;
      }

      .message {
        width: 100%;
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        background-color: white;
        padding: 15px;
        font-size: 1.1em;
        margin-bottom: 20px;

        img {
          display: block;
          margin: 10px auto;
          max-width: 100%;
        }
      }
    }

    .toastr-right-container {
      width: 10%;
    }

    .close-toastr {
      width: 10%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      font-size: 22px;
      border: none;
      outline: none;
      opacity: 0.5;
      cursor: pointer;
      font-family: "Helvetica Neue", Helvetica, Arial sans-serif;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }

    &.info, &.success, &.warning, &.error {
      color: white;
    }

    &.info {
      background-color: $infoBgColor;

      .toastr-progressbar {
        background-color: darken($infoBgColor, 15%);
      }
    }

    &.success {
      background-color: $successBgColor;

      .toastr-progressbar {
        background-color: darken($successBgColor, 15%);
      }
    }

    &.warning {
      background-color: $warningBgColor;

      .toastr-progressbar {
        background-color: darken($warningBgColor, 15%);
      }
    }

    &.error {
      background-color: $errorBgColor;

      .toastr-progressbar {
        background-color: darken($errorBgColor, 15%);
      }
    }

    &.light {
      .toastr-progressbar {
        background-color: #ccc;
      }
      .toastr-icon {
        fill: #333 !important;
      }
    }

    // &.message {
    //   opacity: 1;
    //   border: 1px solid #dbdbdb;
      
      

    //   .close-toastr {
    //     height: 50px;
    //   }
    // }

    .toastr-progress-container {
      height: 5px;
      margin: 0 -20px -20px -60px;
      position: absolute;
      bottom: 20px;
      width: 100%;

      .toastr-progressbar {
        -webkit-border-radius: 0 0 0 4px;
        -moz-border-radius: 0 0 0 4px;
        border-radius: 0 0 0 4px;
        height: 100%;
      }
    }
  }

  .toastr-attention {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

.dropdown-submenu {
    position: relative;

    .dropdown-menu{
        top: 0;
        left: 100%;
        margin-top: -1px;
    }
    
    span.caret{
        -ms-transform: rotate(270deg); /* IE 9 */
        -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
        transform: rotate(270deg);
    }
}

.bt-training{
    border: 1px solid #23c6c8;
    border-top-width: 6px;
}
.bt-primary{
    border: 1px solid #1ab394;
    border-top-width: 6px;
}

.popover{
    max-width: 100%;
}

label.checkbox-label{
    font-weight: normal;

    input{
        vertical-align: bottom
    }
}

@media only screen and (max-width: 768px) {
    .item-attrs{
        .item-attrs-row{
            .label-col{
                text-align: left;
            }
        }
    }
}

@media print{
    html {
        height: auto;
    }
    .print-button{
        display: none;
    }
}

.more-link{
    cursor  : pointer;
    color   : blue;  
    
    i{
        font-size: 12px;
        vertical-align: middle;
    }
}

.Select-menu-outer{
    z-index: 9999;
    background: lightgreen;
}

.checklist-section{
    overflow : inherit;
    .checkitem-body{
        overflow: inherit;   
    }
    .checkitem{
        overflow : inherit;        
        
    }
}

.doc-data-table{
    td.data-key{
        width       : 8.33333%;
        text-align  : right;
        background  : palegoldenrod;

        width       : 15%;
        // border-top  : none;
    }
    td.doc-props-label-col{
        width: 15%;
    }
    
    margin: 5px 10px 10px 10px;
    width: calc(100% - 20px);
}

.doc-data-table-report {
    border-left: 0px;
    border-right: 0px;
    td.doc-props-label-col {
        border-left: 0px;
    }
    td.doc-props-value-col {
        border-right: 0px;
    }
}

.options-editor-content{
    height : 400px;

    .list-actions{
        button{
            border : none;
        }
    }

   .table-container{
       max-height: 350px;
       overflow-y: auto;
       td.index-col{
        width: 35px;
        padding-right: 0;
        vertical-align: middle;
       }
       td.action-col{
           width    : 115px;
           vertical-align: middle;
           .btn-xs{
               border   : none;
           }
       }
       
       tr.hover-row{
           min-height : 37px;
           height: 37px;
           
           button{
               display : none;
           }

           &:hover, &.active{
               button{
                   display : unset;
               }
           }
       }
   } 
}

pre,
blockquote {
    page-break-inside: avoid;
}
thead {
    display: table-header-group;
}
tr,
img {
    page-break-inside: avoid;
}